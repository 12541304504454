import React from 'react'
import { Link } from 'gatsby'

const SimpleNav = (props) => (
    <nav id="nav" className={props.sticky ? 'alt' : ''}>
        <ul>
            <li>
                <Link to="/" className="link primary">About Us</Link>
            </li>
            <li>
                <Link to="/property-developments" className="link primary">Property Developments</Link>
            </li>
            <li>
                <Link to="/rental-stocks" className="link primary">Rental Stocks</Link>
            </li>
            <li>
                <Link to="/mineral-supplies" className="link primary">Mineral Supplies</Link>
            </li>
        </ul>
    </nav>
)

export default SimpleNav
