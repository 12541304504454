import React from 'react'
import Helmet from 'react-helmet'
import Waypoint from 'react-waypoint'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'

import Layout from '../components/layout'
// import Lightbox from 'react-images'
import Gallery from '../components/Gallery'
import SimpleNav from '../components/SimpleNav'

import thumb01 from '../assets/images/thumbs/01.jpg'
import thumb02 from '../assets/images/thumbs/02.jpg'
import thumb03 from '../assets/images/thumbs/03.jpg'
import thumb04 from '../assets/images/thumbs/04.jpg'
import thumb05 from '../assets/images/thumbs/05.jpg'
import thumb06 from '../assets/images/thumbs/06.jpg'

import dev01 from '../assets/images/development/lonehill-X41.jpg'
import dev02 from '../assets/images/development/germiston.jpeg'

import ms01 from '../assets/images/minerals/chrome.jpg'
import ms02 from '../assets/images/minerals/manganese.jpg'
import ms03 from '../assets/images/minerals/copper.jpg'
import ms04 from '../assets/images/minerals/iron.jpg'
import msf01 from '../assets/images/minerals/chrome-ore.png'
import msf02 from '../assets/images/minerals/manganese-ore.png'
import msf03 from '../assets/images/minerals/copper-ore.png'
import msf04 from '../assets/images/minerals/iron-ore.png'
// import pt from '../assets/images/minerals/periodic-table.svg'
import pt from '../assets/images/minerals/periodic-table.png'
import mst01 from '../assets/images/minerals/chrome-thumb.png'
import mst02 from '../assets/images/minerals/manganese-thumb.png'
import mst03 from '../assets/images/minerals/copper-thumb.png'
import mst04 from '../assets/images/minerals/iron-thumb.png'

import rental01 from '../assets/images/rental/morningside-manor.jpeg'
import rental02 from '../assets/images/rental/parkwood-rosebank.jpeg'

import full01 from '../assets/images/fulls/01.jpg'
import full02 from '../assets/images/fulls/02.jpg'
import full03 from '../assets/images/fulls/03.jpg'
import full04 from '../assets/images/fulls/04.jpg'
import full05 from '../assets/images/fulls/05.jpg'
import full06 from '../assets/images/fulls/06.jpg'

const DEFAULT_IMAGES = [
    { id: '1', src: dev01, thumbnail: dev01, caption: 'Lonehill X41', description: '3D rendering.'},
    { id: '2', src: dev02, thumbnail: dev02, caption: 'Germiston', description: 'Front View.'},
    { id: '3', src: rental01, thumbnail: rental01, caption: 'Morningside Manor', description: 'Price ZAR 4500/pm.'},
    { id: '4', src: rental02, thumbnail: rental02, caption: 'Parkwood Rosebank', description: 'Price ZAR 5000/pm.'}
];

const CD_IMAGES = [
    { id: '1', src: dev01, thumbnail: dev01, caption: 'Lonehill X41', description: 'It\'s situated in the safest suburb of Johannesburg. The property size including roads is 1.95 Hectares (Ha). It is zoned for residential, maximum 3 storeys. It has a density of 70 units/Ha with coverage of 30% and floor area ratio of 0.8. Ideal for walk-up apartments (85-100sqm 2-3bedrooms/unit) or freestanding clusters (150-200sqm 3bedrooms/unit).'},
    { id: '2', src: dev02, thumbnail: dev02, caption: 'Germiston', description: 'It\'s surrounded by industrial areas, merely 1 min drive from the N17 highway, and 5 min walking distance to the Reef Shopping Centre. The site is 8000sqm, zoned for 84 walk-up residential apartments. Phase 1 (18 units) all sold and transferred. Remaining units 66. Average unit size 62sqm (2 bedrooms and 1 bath).'}
];

const MS_IMAGES = [
    { id: '1', src: pt, thumbnail: pt, caption: 'Available Minerals'},
    { id: '2', src: msf01, thumbnail: msf01, caption: 'Chromite Ore'},
    { id: '3', src: msf02, thumbnail: msf02, caption: 'Manganese Ore'},
    { id: '4', src: msf03, thumbnail: msf03, caption: 'Copper Ore'},
    { id: '5', src: msf04, thumbnail: msf04, caption: 'Iron Ore'}
];

const MS_BACKUP_IMAGES = [
    { id: '1', src: ms01, thumbnail: mst01, caption: 'Chromite Ore', description: 'This brownish-black oxide of chromium and iron has the chemical structure of FeCr₂O₄, usages include Refactory, Casting, Blasting, Grinding, Lapping in foundry and steel industries.'},
    { id: '2', src: ms02, thumbnail: mst02, caption: 'Manganese Ore', description: 'This silver-gray brittle metal has the atomic number of 25, can be used to increase the hardness of steel.'},
    { id: '3', src: ms03, thumbnail: mst03, caption: 'Copper Ore', description: 'This chemical element can be mined to conduct electricity.'},
    { id: '4', src: ms04, thumbnail: mst04, caption: 'Iron Ore', description: 'This type of ore is being used to produce steel.'}
];

const RS_IMAGES = [
    { id: '1', src: rental01, thumbnail: rental01, caption: 'Morningside Manor', description: 'Conveniently located in the the heart of Morningside Manor. Walking distance to Pavilion Shopping Centre and The Wedge Shopping Centre. A house share that stands on an erf size of more than 4000sqm, has more than 6 bedrooms to choose from. Can be en-suite or bedrooms only. Can choose to have a private kitchen or a common kitchen to share. Tenants also have the option to rent a garage for their cars.'},
    { id: '2', src: rental02, thumbnail: rental02, caption: 'Parkwood Rosebank', description: 'Conveniently located in the hub of Rosebank. Easy access to Gautrain Rosebank Station, Rosebank Mall, and the top rated restaurants in our neighborhood Parktown North. A house share that stands on an erf size of more than 2000sqm, has more than 6 bedrooms to choose from. Can be en-suite or bedrooms only. Can choose to have a private kitchen or a common kitchen to share.'}
];

class HomeIndex extends React.Component {

    constructor() {
        super();

        this.state = {
            lightboxIsOpen: false,
            currentImage: 0,
            stickyNav: false,
        };

        this.closeLightbox = this.closeLightbox.bind(this);
        this.gotoNext = this.gotoNext.bind(this);
        this.gotoPrevious = this.gotoPrevious.bind(this);
        this.openLightbox = this.openLightbox.bind(this);
        this.handleClickImage = this.handleClickImage.bind(this);
        // this._handleWaypointEnter = this._handleWaypointEnter.bind(this);
        // this._handleWaypointLeave = this._handleWaypointLeave.bind(this);
    }

    openLightbox (index, event) {
        event.preventDefault();
        this.setState({
            currentImage: index,
            lightboxIsOpen: true,
        });
    }
    closeLightbox () {
        this.setState({
            currentImage: 0,
            lightboxIsOpen: false,
        });
    }
    gotoPrevious () {
        this.setState({
            currentImage: this.state.currentImage - 1,
        });
    }
    gotoNext () {
        this.setState({
            currentImage: this.state.currentImage + 1,
        });
    }
    handleClickImage () {
        if (this.state.currentImage === this.props.images.length - 1) return;

        this.gotoNext();
    }
    // _handleWaypointEnter () {
    //     this.setState(() => ({ stickyNav: false }));
    // }
    // _handleWaypointLeave () {
    //     this.setState(() => ({ stickyNav: true }));
    // }
    _handleWaypointEnter= () => {
        this.setState(() => ({ stickyNav: false }));
      }
    
      _handleWaypointLeave = () => {
        this.setState(() => ({ stickyNav: true }));
      }

    render() {
        const siteTitle = "Property Developments | House Rentals in Johannesburg | Mineral Supplies from South Africa | The Fu Foundation"
        const siteDescription = "The Fu Foundation offers property development opportunities, affordable house rentals in Johannesburg and mineral supplies from South Africa. "
        const siteKeywords = "Property Developments, House Rentals, Mineral Supplies, Minerals South Africa, Rentals Johannesburg, Property South Africa, Property For Sale, Lonehill Property for sale, Germiston Property for sale, Lonehill Property, Germiston Property"
        let currentProps = this.props.data.fileName.childImageSharp.fluid
        // let normalizedProps = {
        //     ...currentProps,
        //     style: {
        //       ...(currentProps.style || {}),
        //       maxWidth: currentProps.fluid.presentationWidth,
        //       maxHeight: currentProps.fluid.presentationHeight,
        //       margin: "0 auto", // Used to center the image
        //     },
        //   }

        return (
            <Layout>
                <Helmet>
                        <title>{siteTitle}</title>
                        <meta name="description" content={siteDescription} />
                        <meta name="keywords" content={siteKeywords} />
                        <meta name="google-site-verification" content="k5kzKRtGfxjwJfj3DWMQIqAwJmBGOhJJDnLeQXKz0Hs" />
                </Helmet>

                <div id="main">

                    <section id="one">
                        <Waypoint
                                onEnter={this._handleWaypointEnter}
                                onLeave={this._handleWaypointLeave}
                        >
                        </Waypoint>
                        <SimpleNav sticky={this.state.stickyNav} />
                        {/* <Nav sticky={this.state.stickyNav} /> */}

                        <header className="major">
                            <h1>Fu Foundation</h1>
                            <Img fixed={this.props.data.fileName.childImageSharp.fixed} />
                            {/* <Img fluid={this.props.data.fileName.childImageSharp.fluid} /> */}
                            {/* <Img fluid={normalizedProps} /> */}
                            <h2>Company Overview</h2>
                        </header>
                        <p>The Fu Foundation is a family business that started back in 1993, in Johannesburg, South Africa. It has been engaging in property developments since 1993. Past developments include the following:</p>
                        <ul>
                            <li><i>Floridain Business Park</i> in Gaueng, in 1993.</li>
                            <li><i>Emerald Residential</i> townhouses in Randpark Ridge, Gauteng, in 1995.</li>
                            <li><i>Emerald Office Park</i> in Randpark Ridge, Gauteng, in 1996.</li>
                            <li><i>Monte Solo Estate</i>, 52 clusters in Lonehill X40, Gauteng, from 2002-2004.</li>
                            <li><i>SasFin Office Park</i>, 9000sqm Gross Leasable Area (GLA) in Waverley, Gauteng, from 2005-2007.</li>
                        </ul>
                        {/* <ul className="actions">
                            <li><a href="#" className="button">Learn More</a></li>
                        </ul> */}
                    </section>

                    {/* <section id="two">
                        <h2>Current Developments</h2>

                        <Gallery images={CD_IMAGES.map(({ id, src, thumbnail, caption, description }) => ({
                            src,
                            thumbnail,
                            caption,
                            description
                        }))} />
                    </section>

                    <section id="three">
                        <h2>Prospective Developments</h2>
                        <div>
                            <h3>Lonehill X53</h3>
                            <p>It's situated in the safest suburb of Johannesburg, nearby Lonehill X41. The property size is 3.2Ha (including roads). It is zoned for residential, maximum 3 storeys. It has density of 50 units/Ha with coverage of 50% and floor area ratio of 0.8. Ideal for freestanding 3-storeys clusters (150-200+sqm 3bedrooms/unit).</p>
                        </div>
                        <div>
                            <h3>Rabie Ridge/Midrand</h3>
                            <p>The site is 129Ha. Mixed rights have been applied for: retail (15Ha), office, residential (from 120 units/Ha), clinics and school.</p>
                        </div>
                        <div>
                            <h3>Bredell AH/Kempton Park</h3>
                            <p>The site has its own dam, less than 2km from the Highway offramp (Pomona). It is 29Ha. Mixed rights to be applied for: retail/office/industrial, residential (from 80 units/Ha).</p>
                        </div>
                        <div>
                            <h3>Poortview/Roodepoort</h3>
                            <p>The site is situated in a region of nature and tranquility. Less than 750m from Walter Sisulu National Botanical Garden. The property is 2Ha. Ideal for guest houses/accommodations.</p>
                        </div>
                        <div>
                            <h3>Vaal Dam/Free State</h3>
                            <p>The property is 816Ha with 9km waterfront. Ideal for mixed zoning rights: retail/business, residential, clinics and schools.</p>
                        </div>
                    </section> */}

                    {/* <section id="four">
                        <h2>Rental Stocks</h2>

                        <Gallery images={RS_IMAGES.map(({ id, src, thumbnail, caption, description }) => ({
                            src,
                            thumbnail,
                            caption,
                            description
                        }))} />

                    </section> */}

                    {/* <section id="five">
                        <h2>Mineral Supplies</h2>
                        <p><i>We are expanding our business horizons to also include the supplying of mineral resources</i>.</p>
                        <p>We currently supply the following minerals: Chromite Ore, Manganese Ore, Iron Ore, and Copper Ore. All these mineral resources will be exported from South Africa.</p>

                        <Gallery images={MS_IMAGES.map(({ id, src, thumbnail, caption, description }) => ({
                            src,
                            thumbnail,
                            caption,
                            description
                        }))} />

                        <div>
                            <h3>Chromite Ore</h3>
                            <p>This brownish-black oxide of chromium and iron has the chemical structure of FeCr₂O₄, usages include Refactory, Casting, Blasting, Grinding, Lapping in foundry and steel industries.</p>
                        </div>

                        <div>
                            <h3>Manganese Ore</h3>
                            <p>This silver-gray brittle metal has the atomic number of 25, can be used to increase the hardness of steel.</p>
                        </div>

                        <div>
                            <h3>Copper Ore</h3>
                            <p>This chemical element can be mined to conduct electricity.</p>
                        </div>

                        <div>
                            <h3>Iron Ore</h3>
                            <p>This type of ore is being used to produce steel.</p>
                        </div>
                    </section> */}

                    {/* <section id="five">
                        <h2>Gallery</h2>

                        <Gallery images={DEFAULT_IMAGES.map(({ id, src, thumbnail, caption, description }) => ({
                            src,
                            thumbnail,
                            caption,
                            description
                        }))} />

                        <ul className="actions">
                            <li><a href="#" className="button">Full Portfolio</a></li>
                        </ul>
                    </section> */}

                    <section id="six">
                        <h2>Get In Touch</h2>
                        <p>Should you require additional information regarding our developments, rental stocks, or the exporting of minerals, please feel free to send us an email. Alternatively, complete the form below and we will get back to you.</p>
                        <div className="row">
                            <div className="8u 12u$(small)">
                                <form name="contact" method="post" data-netlify="true" data-netlify-honeypot="bot-field">
                                    <input type="hidden" name="bot-field" />
                                    <div className="row uniform 50%">
                                        <div className="6u 12u$(xsmall)"><input type="text" name="name" id="name" placeholder="Name" /></div>
                                        <div className="6u 12u$(xsmall)"><input type="email" name="email" id="email" placeholder="Email" /></div>
                                        <div className="12u"><textarea name="message" id="message" placeholder="Message" rows="4"></textarea></div>
                                    </div>
                                    <br/>
                                    <ul className="actions">
                                        <li><input type="submit" value="Send Message" /></li>
                                        <li><input type="reset" value="Clear" /></li>
                                    </ul>
                                </form>
                            </div>
                            <div className="4u 12u$(small)">
                                <ul className="labeled-icons">
                                    <li>
                                        <h3 className="icon fa-home"><span className="label">Address</span></h3>
                                        13 Chester Rd.<br />
                                        Parkwood, Johannesburg<br />
                                        South Africa
                                    </li>
                                    {/* <li>
                                        <h3 className="icon fa-mobile"><span className="label">Phone</span></h3>
                                        (+27) 082-419-2782<br />
                                        (+886) 0952-881-126
                                    </li> */}
                                    <li>
                                        <h3 className="icon fa-envelope-o"><span className="label">Email</span></h3>
                                        <a href="mailto:info@thefufoundation.com" target="_top">info@thefufoundation.com</a><br/>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </section>
                    {/* fluid(maxWidth: 200, maxHeight: 253) {
          ...GatsbyImageSharpFluid
        } */}
                </div>

            </Layout>
        )
    }
}

export default HomeIndex

export const query = graphql`
  query {
    fileName: file(relativePath: { eq: "images/logo-leaves-jhb.png" }) {
      childImageSharp {
        fixed(height: 379) {
            ...GatsbyImageSharpFixed
          }
      }
    }
  }
`;